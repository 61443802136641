<template>
  <div class="flex flex-col justify-center items-center">
    <div
      v-if="promptText"
      class="flex w-full items-center pb-6">
      <div class="h-px bg-ink-2 w-full"></div>
      <p class="typeset-4 whitespace-no-wrap mx-3 text-ink-4">
        {{ promptText }}
      </p>
      <div class="h-px bg-ink-2 w-full"></div>
    </div>
    <div class="grid grid-cols-2 gap-4 w-full">
      <a
        v-for="(provider, index) in oAuthProviders"
        :key="provider.provider"
        :href="urlWithSecretParam(provider.url)"
        class="flex items-center btn btn-large btn-secondary bg-white w-full"
        data-method="post"
        :class="columnSpan(index)">
        <svg-icon
          :name="`${provider.provider}-icon`"
          height="18px"
          width="18px"
          class="mr-3 -mt-2" />
        {{ t(`login-modal.${provider.provider}`) }}
      </a>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";

export default {
  name: "SocialLogin",
  components: {
    SvgIcon
  },
  props: {
    oAuthProviders: {
      type: Object,
      required: true
    },
    queryParams: {
      type: String,
      // This default state will redirect the user to "/"
      // We need to pass something othwerise Google sends over a hashed state propertly that has no redirect value
      default: "?state=eyJyZWRpcmVjdFVybCI6Ii8ifQ=="
    },
    promptText: {
      type: String,
      default: "Log in with"
    }
  },
  computed: {
    urlWithSecretParam() {
      return url => `${url}${this.queryParams}`;
    },
    columnSpan() {
      return index => {
        if (this.isMobile) {
          return "col-span-2";
        }

        if (
          this.oAuthProviders.length % 2 === 0 ||
          (this.oAuthProviders.length % 2 === 1 &&
            index === this.oAuthProviders.length - 1)
        ) {
          return "col-span-1";
        }
        return "col-span-2";
      };
    },
    isMobile() {
      return window.innerWidth < 992;
    }
  }
};
</script>
