<template>
  <div class="flex flex-col h-full justify-between">
    <h2
      class="typeset-3 text-balance mb-5 md:mb-3 text-center md:text-left"
      :class="{ 'md:typeset-2 md:text-center': layout === 'grid' }">
      {{ t(title) }}
    </h2>
    <div class="md:mb-5">
      <ul
        ref="perksContainerEl"
        class="perks-container -mx-4 md:mx-0 list-unstyled snap-x snap-mandatory flex overflow-auto md:grid md:gap-x-4 md:gap-y-7 grid-cols-2 text-center mb-5 md:my-auto scroll-behavior-smooth overscroll-x-contain"
        :class="{ 'md:grid-cols-4': layout === 'row' }">
        <li
          v-for="(perk, index) in perks"
          :key="index"
          ref="perkEls"
          :class="{ 'snap-start': index % 2 === 0 }"
          class="perk w-1/2 md:w-auto px-4 md:px-0 flex-no-shrink flex flex-col items-center">
          <div class="px-3 md:w-auto md:px-0">
            <img
              :src="perk.image"
              :srcset="`${perk.imageRetina} 2x`"
              alt="Perk image"
              class="mb-4 img-responsive" />
          </div>
          <p class="text-balance">{{ t(perk.titleKey) }}</p>
        </li>
      </ul>
    </div>
    <ul class="list-unstyled flex md:hidden gap-4 justify-center mb-3">
      <li
        v-for="(dot, index) in dotsCount"
        :key="index"
        :class="{
          'bg-ink-1': index === activeDotIndex,
          'bg-ink-3': index !== activeDotIndex
        }"
        class="h-3 w-3 br-circle transition-background"></li>
    </ul>
    <div
      v-if="showCta"
      class="mt-auto relative hidden md:block"
      data-cy="perks-panel-login">
      <FadeTransition cross-fade>
        <button
          v-if="isLogin"
          class="btn btn-large btn-primary btn-block mt-auto"
          @click="toggleModalState">
          {{ t("login-modal.create-an-account") }}
        </button>
        <button
          v-else
          class="btn btn-large btn-secondary btn-block mt-auto"
          @click="toggleModalState">
          {{ t("register-modal.already-registered-cta") }}
        </button>
      </FadeTransition>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import perks from "@/app/javascript/utils/perksData";
import FadeTransition from "../transitions/FadeTransition.vue";
import useUserNavStore from "../../stores/user-nav-store";

defineProps({
  title: {
    type: String,
    required: true
  },
  layout: {
    type: String,
    default: "grid"
  },
  showCta: {
    type: Boolean,
    default: false
  }
});

const userNavStore = useUserNavStore();

const dotsCount = computed(() => Math.ceil(perks.length / 2));
const activeDotIndex = ref(0);

const { accountModalState } = storeToRefs(userNavStore);

const isLogin = computed(() => accountModalState.value === "login");

const toggleModalState = () => {
  userNavStore.accountModalState = isLogin.value ? "register" : "login";
};

const perksContainerEl = ref(null);
const perkEls = ref([]);

const carouselObserver = new IntersectionObserver(
  observations => {
    observations.forEach(observation => {
      // toggle --in-view class if intersecting or not
      observation.target.classList.toggle(
        "--in-view",
        observation.isIntersecting
      );
      if (observation.isIntersecting) {
        const index = perkEls.value.indexOf(observation.target);
        activeDotIndex.value = Math.floor(index / 2);
      }
    });
  },
  {
    root: perksContainerEl.value,
    threshold: 0.6
  }
);

const goToElement = ({ scrollport, element }) => {
  const delta = Math.abs(scrollport.offsetLeft - element.offsetLeft);
  const scrollerPadding = parseInt(
    getComputedStyle(scrollport)["padding-left"],
    10
  );

  const pos =
    scrollport.clientWidth / 2 > delta
      ? delta - scrollerPadding
      : delta + scrollerPadding;

  scrollport.scrollTo(pos, 0);
};

onMounted(() => {
  const els = perkEls.value;
  els.forEach(element => {
    carouselObserver.observe(element);
  });

  let index = 2;
  setInterval(() => {
    const scrollport = perksContainerEl.value;
    const elements = perkEls.value;

    if (!scrollport || !elements || elements.length === 0) {
      return;
    }

    const element = elements[index];
    if (!element) {
      return;
    }

    goToElement({
      scrollport,
      element
    });

    index = index === 2 ? 0 : 2;
  }, 4000);
});
</script>

<style scoped>
.perks-container:has(> li.--in-view:first-child) + ul > li:first-child,
.perks-container:has(> li.--in-view:last-child) + ul > li:last-child {
  background-color: var(--ink);
}
</style>
