import Image1 from "@/app/assets/images/account-modal/1.png";
import Image1Retina from "@/app/assets/images/account-modal/1@2x.png";
import Image2 from "@/app/assets/images/account-modal/2.png";
import Image2Retina from "@/app/assets/images/account-modal/2@2x.png";
import Image3 from "@/app/assets/images/account-modal/3.png";
import Image3Retina from "@/app/assets/images/account-modal/3@2x.png";
import Image4 from "@/app/assets/images/account-modal/4.png";
import Image4Retina from "@/app/assets/images/account-modal/4@2x.png";

export default [
  {
    image: Image1,
    imageRetina: Image1Retina,
    titleKey: "login-modal.usp-1",
    titleKeyOverlay: "login-button-overlay.usp-1"
  },
  {
    image: Image2,
    imageRetina: Image2Retina,
    titleKey: "login-modal.usp-2",
    titleKeyOverlay: "login-button-overlay.usp-2"
  },
  {
    image: Image3,
    imageRetina: Image3Retina,
    titleKey: "login-modal.usp-3",
    titleKeyOverlay: "login-button-overlay.usp-3"
  },
  {
    image: Image4,
    imageRetina: Image4Retina,
    titleKey: "login-modal.usp-4",
    titleKeyOverlay: "login-button-overlay.usp-4"
  }
];
